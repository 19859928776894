import React, {useEffect, useState} from 'react';
import Layout from './layouts/Layout';
import {checkOrder, getFromSearchString} from './utils/functions';
import {Toaster} from 'react-hot-toast';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import LoadPanel from './components/LoadPanel';
import Checkout from './pages/Checkout';
import Payment from './pages/Payment';
import Status from './pages/Status';
import Card from './pages/Card';
import Crypto from './pages/Crypto';
import ByRequisites from './pages/ByRequisites';

import './app.scss';

const screenSize = window.innerWidth < 1024 ? 'mobile' : 'desktop';

const App = () => {
  const {t, i18n} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const page = getFromSearchString(location.pathname, 2);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const getCheckoutOrder = async () => {
      const hashUrlid = getFromSearchString(location.pathname);

      if (hashUrlid) {
        await checkOrder(hashUrlid, setOrder);
      }
    };

    getCheckoutOrder().catch((e) => {
      console.warn(e);
    });
  }, []);

  useEffect(() => {
    if (page === 'list' && order && order.MerchantOrderStatusID !== 1) {
      history.replace(location.pathname.replace('list', 'status'));
    }
  }, [order]);

  const onLanguageChange = async (event) => {
    const languageValue = event.target.value;

    await i18n.changeLanguage(languageValue);

    history.push({
      pathname: location.pathname,
      search: `?lang=${languageValue.toUpperCase()}`,
      hash: location.hash,
    });
  }

  return order && order.Urlid ? (
    <div className='app'>
      <Toaster/>
      <div id={'locale-switcher-wrapper'} className={`${screenSize}-switcher`}>
        <div id={'locale-switcher-inner'}>
          <select className={'switcher'} onChange={onLanguageChange} value={t('language')}>
            <option className={'switcher-item'} value="en">English</option>
            <option className={'switcher-item'} value="uk">Українська</option>
            <option className={'switcher-item'} value="ru">Русский</option>
          </select>
        </div>
      </div>
      <Layout
        order={order}
        screenSize={screenSize}
        currentPage={page}
      >
        <div className={`content content-${screenSize}`}>
          <Switch>
            <Route
              path='/checkout/list'
              render={(props) => (
                <Checkout {...props} order={order} screenSize={screenSize}/>
              )}
            />
            <Route
              path='/checkout/payment'
              render={(props) => (
                <Payment {...props} order={order} screenSize={screenSize}/>
              )}
            />
            <Route
              path='/checkout/crypto'
              render={(props) => (
                <Crypto {...props} order={order} screenSize={screenSize}/>
              )}
            />
            <Route
              path='/checkout/requisites'
              render={(props) => (
                <ByRequisites {...props} order={order} screenSize={screenSize}/>
              )}
            />
            <Route
              path='/checkout/status'
              render={(props) => (
                <Status {...props} order={order} screenSize={screenSize}/>
              )}
            />
            <Route
              path='/checkout/card'
              render={(props) => (
                <Card {...props} order={order} screenSize={screenSize}/>
              )}
            />
            <Redirect to={'/checkout/list'}/>
          </Switch>
        </div>
      </Layout>
    </div>
  ) : (
    <LoadPanel/>
  );
};

export default App;
