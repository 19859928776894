import React from 'react';
import {getMethodIconById, groupToMap, sendRequest, notify} from '../utils/functions';
import {withTranslation} from 'react-i18next';
import classNames from 'classnames/bind';
import {vars} from '../utils/variables';
import {connect} from 'react-redux';
import {setInstance} from '../utils/reducers/orderAmountReducer';
import PreRequisitesScreen from './ByRequisites/PreRequisitesScreen';
import LoadPanel from './LoadPanel';

import '../styles/content.scss';

const {VIEW: {MOBILE}, SUCCESS_API_CODE, KIND_IDS: {CRYPTO, BY_REQUISITES}} = vars;

class Content extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      methods: [],
      payRetailers: [],
      payRetailersPaymentSystemTypeID: null,
      orderAmounts: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getPaymentMethods().catch((e) => {
      console.warn(e);
    }).finally(() => {
      this.setState({isLoading: false});
    });
  }

  goBack = () => {
    const {dispatch} = this.props;

    dispatch(setInstance(null));

    this.setState({
      paymentCurrencyID: null,
      orderAmounts: null,
      isShowAttention: false
    });
  };

  getPaymentMethods = async () => {
    const {order: {Urlid}} = this.props;

    const methods = await sendRequest('CheckoutOrder/GetPaymentSystemTypes', {
      Params: {
        Urlid: Urlid
      }
    });

    const payRetailers = await sendRequest('CheckoutOrder/GetPayRetailersPaymentMethods', {
      Params: {
        Urlid: Urlid
      }
    });

    if (methods && methods['ResponseCode'] === SUCCESS_API_CODE) {
      const list = methods['Response']['PaymentSystemType'];

      this.setState({
        methods: list,
      });
    } else {
      console.error(methods.ResponseCode + ' ' + methods.ResponseText);
    }

    if (payRetailers && payRetailers['ResponseCode'] === SUCCESS_API_CODE) {
      const list = payRetailers['Response']['PayRetailersPaymentMethods'] || [];
      const paymentSystemTypeID = payRetailers['Response']['PaymentSystemTypeID'];

      const channels = groupToMap(list, item => item.Channel, 'OrderPriority');
      const channelsElements = [];

      channels.forEach((value, key) => {
        const groupName = key.toLowerCase();
        channelsElements.push((
          <div key={key} className={'accordion-item'}>
            <button
              id={`accordion-item-${groupName}`}
              className={'accordion'}
              onClick={(event) => {
                event.target.classList.toggle("active");

                const panel = event.target.nextElementSibling;
                if (panel.style.display === "block") {
                  panel.style.display = "none";
                } else {
                  panel.style.display = "block";
                }
              }}
            >
              {groupName}
            </button>
            <div className="panel">
              <div className="panel-inner">
                {value.map((item, key) => {
                  return (
                    <div
                      id={`method_${paymentSystemTypeID}_${item['PaymentMethodID']}_${key}`}
                      key={`${item['PaymentMethodID']}-${key}`}
                      className={'payment-method'}
                      onClick={this.onMethodClick}
                    >
                      <div className={'payment-method-icon'}>
                        <img src={item['ImageUrl']} alt=""/>
                      </div>
                      <div className={'payment-method-name'}>
                        {item['Name']}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ));
      });

      this.setState({
        payRetailers: channelsElements,
      });
    } else {
      console.error(payRetailers.ResponseCode + ' ' + payRetailers.ResponseText);
    }
  };

  initPayment = async (params) => {
    const initPayment = await sendRequest('CheckoutOrder/InitPayment', {
      Params: params,
    });

    if (initPayment && initPayment['ResponseCode'] === SUCCESS_API_CODE) {
      return initPayment['Response'];
    } else {
      throw new Error(initPayment['ResponseText']);
    }
  }

  onOrderAmountClick = (e) => {
    const currencyId = e.target.value;

    this.setState({paymentCurrencyID: currencyId});
  }

  onOrderAmountNext = () => {
    const {
      urlid,
      methodId,
      isShowCardForm,
      paymentCurrencyID,
      kindId,
      orderAmounts,
    } = this.state;
    const {order: {CurrencyID}} = this.props;


    const selectedOrderAmount = orderAmounts.find((order) => order.CurrencyID === parseInt(paymentCurrencyID));

    this.componentStrategy({
        urlid: urlid,
        methodId: methodId,
        isShowCardForm: isShowCardForm,
        paymentOrderAmount: selectedOrderAmount,
        paymentCurrencyID: paymentCurrencyID,
        kindId: kindId,
        orderCurrencyId: CurrencyID,
      }
    );
  }

  onMethodClick = async (e) => {
    const {methods} = this.state;
    const {order: {Urlid, CurrencyID}} = this.props;
    const id = e.currentTarget.id;
    let orders = JSON.parse(e.currentTarget.dataset.orders);
    const split = id.split('_');
    const methodId = parseInt(split[1], 10);
    const kindId = parseInt(split[2], 10);

    const currentMethod = methods.find(item => item['PaymentSystemKindID'] === kindId && item['ID'] === methodId);

    if (kindId !== CRYPTO && orders && orders.length > 1) {
      this.setState({
        orderAmounts: orders,
        urlid: Urlid,
        methodId: methodId,
        isShowCardForm: currentMethod['IsShowCardForm'],
        kindId: kindId,
      });
      return;
    }

    this.componentStrategy({
      urlid: Urlid,
      methodId: methodId,
      isShowCardForm: currentMethod['IsShowCardForm'],
      paymentOrderAmount: orders && orders[0],
      paymentCurrencyID: orders && orders[0].CurrencyID,
      kindId: kindId,
      orderCurrencyId: CurrencyID,
    });
  };

  componentStrategy = (state) => {
    const {dispatch, history} = this.props;
    const {
      urlid,
      methodId,
      isShowCardForm,
      kindId,
      orderCurrencyId,
      paymentOrderAmount,
      paymentCurrencyID,
    } = state;

    const {CurrencyID} = paymentOrderAmount;

    if (isShowCardForm === 1) {
      dispatch(setInstance({
        paymentOrderAmount: paymentOrderAmount,
        isDifferentCurrency: CurrencyID !== orderCurrencyId
      }));

      history.push({
        pathname: `/checkout/card/${urlid}/${methodId}`
      });
      return;
    }

    if (methodId === 0) {
      history.push(`/checkout/payment/${urlid}`);
      return;
    }

    if (kindId === CRYPTO) {
      history.push(`/checkout/crypto/${urlid}`);
      return;
    }

    if (kindId === BY_REQUISITES) {
      dispatch(setInstance({
        paymentOrderAmount: paymentOrderAmount,
        isDifferentCurrency: CurrencyID !== orderCurrencyId
      }));

      this.setState({
        isShowAttention: true,
        orderAmounts: null,
        urlid: urlid,
        methodId: methodId,
        isShowCardForm: isShowCardForm,
        paymentOrderAmount: paymentOrderAmount,
        paymentCurrencyID: paymentCurrencyID,
        kindId: kindId,
        orderCurrencyId: orderCurrencyId,
      });
      return;
    }

    const params = {
      PaymentMethodID: kindId ? kindId : '',
      PaymentSystemTypeID: methodId,
      Urlid: urlid,
    }

    if (paymentCurrencyID) {
      params.PaymentCurrencyID = parseInt(paymentCurrencyID, 10);
    }

    this.initPayment(params).then((response) => {
      window.location.href = response['RedirectURL'];
    }).catch((error) => {
      notify(error);
    });
  }

  onConfirm = () => {
    const {history} = this.props;
    const {
      kindId,
      methodId,
      paymentCurrencyID,
      urlid,
    } = this.state;

    const params = {
      PaymentMethodID: kindId,
      PaymentSystemTypeID: methodId,
      Urlid: urlid,
      PaymentCurrencyID: parseInt(paymentCurrencyID),
    }

    this.initPayment(params).then(() => {
      history.push(`/checkout/requisites/${urlid}/${methodId}`);
    }).catch((error) => {
      notify(error.message);
    }).finally(() => {
      this.setState({isLoading: false});
    });

    this.setState({isLoading: true});
  }

  render() {
    const {
      isLoading,
      methods,
      payRetailers,
      orderAmounts,
      paymentCurrencyID,
      isShowAttention,
    } = this.state;
    const {t, screenSize} = this.props;

    if (isLoading) {
      return (<LoadPanel/>);
    }

    return orderAmounts ? (
      <div className={'order-amounts-wrapper'}>
        <div className={'order-amounts-inner'}>
          {orderAmounts.map((item, index) => {
            return (
              <label
                key={`order-amount-${index}`}
                htmlFor={`order-amount-${index}`}
                className={classNames('order-amount-label', {
                  'selected-order': parseInt(paymentCurrencyID) === item.CurrencyID,
                })}
              >
                <div className={'order-amount-inner'}>
                  <input
                    type={'radio'}
                    id={`order-amount-${index}`}
                    name={'order-amount'}
                    value={item.CurrencyID}
                    onClick={this.onOrderAmountClick}/>
                  <img width={25} height={25} src={item.CurrencyImageUrl} alt=""/>
                  <span>{item.CurrencyCode}</span>
                </div>
                <div className={'order-amount-inner'}>
                  <span>{item.Amount}</span>
                  <span>{item.CurrencySymbol}</span>
                </div>
              </label>
            );
          })}
        </div>
        <div className={'auto-aligned'}>
          <div className={'important-currency-message'}>
            <span className={'bold-text'}>{t('message_title')}</span>
            <p>{t('message_text')}</p>
          </div>
        </div>
        <div className={classNames('accounts-buttons', {
          'mobile-buttons': screenSize === MOBILE,
        })}>
          <button
            onClick={() => {
              this.setState({orderAmounts: null, paymentCurrencyID: null});
            }}
          >
            {t('cancel')}
          </button>
          <button
            disabled={!paymentCurrencyID}
            className={!paymentCurrencyID ? 'button-disabled' : 'button-enabled'}
            onClick={this.onOrderAmountNext}
          >
            {t('next')}
          </button>
        </div>
      </div>
    ) : isShowAttention ? (
      <PreRequisitesScreen onConfirm={this.onConfirm} onCancel={this.goBack} isMobile={screenSize === MOBILE}/>
    ) : (
      <div className={'list-wrapper'}>
        <div className={'content-title'}>
          {`${t('payment_method')}:`}
        </div>
        <div className={'content-inner scrollable'}>
          <div className={'scrollable-wrapper'}>
            <div className={'scrollable-container'}>
              <div className={'scrollable-content'}>
                <div className={'payments-wrapper'}>
                  {methods.map((method) => {
                    const icon = getMethodIconById(method['ID']);

                    return (
                      <div
                        id={`method_${method['ID']}_${method['PaymentSystemKindID']}`}
                        key={method['ID']}
                        className={'payment-method'}
                        onClick={this.onMethodClick}
                        data-orders={JSON.stringify(method['OrderAmounts'])}
                      >
                        <div className={'payment-method-icon'}>
                          <img src={icon} alt=""/>
                        </div>
                        <div className={'payment-method-name'}>
                          {method['Name']}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {payRetailers.length > 0 && (
                  <div className="content-title">LATAM Payment Methods:</div>
                )}
                <div className={'payments-wrapper'}>
                  {payRetailers}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ConnectedComponent = connect()(Content);
export default withTranslation()(ConnectedComponent);
