import React from 'react';
import Content from '../components/Content';

const Checkout = (props) => {
	return (
		<Content {...props}/>
	);
};

export default Checkout;
