import React, {useEffect, useState} from 'react';
import useInput from '../utils/customHooks/useInput';
import useCardInput from '../utils/customHooks/useCardInput';
import useSelect from '../utils/customHooks/useSelect';
import useCvvInput from '../utils/customHooks/useCvvInput';
import {useHistory, useLocation} from 'react-router-dom';
import {getFromSearchString, sendRequest, notify} from '../utils/functions';
import LoadPanel from '../components/LoadPanel';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';

import Classnames from 'classnames';
import {vars} from '../utils/variables';
import '../styles/Card.scss';
import {setInstance} from '../utils/reducers/orderAmountReducer';
import loadPanel from '../components/LoadPanel';

const {SUCCESS_API_CODE} = vars;

const Card = () => {
  const orderAmount = useSelector((state) => state.orderAmount.instance);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const urlid = getFromSearchString(location.pathname, 3);
  const paymentSystemKindID = getFromSearchString(location.pathname, 4);
  const goBack = () => {
    history.push(`/checkout/list/${urlid}`);
  };

  const createCardPayment = async (params) => {
    setIsLoading(true);
    const d = new Date();
    const response = await sendRequest('CheckoutOrder/CreateCardPayment', {
      Params: {
        BrowserAcceptHeader: 'text/html',
        BrowserColorDepth: window.screen.colorDepth,
        BrowserLanguage: window.navigator.language,
        BrowserScreenHeight: window.screen.height,
        BrowserScreenWidth: window.screen.width,
        BrowserTimezone: d.getTimezoneOffset(),
        BrowserUserAgent: window.navigator.userAgent,
        BrowserJavaEnabled: true,
        BrowserWindowHeight: window.innerHeight,
        BrowserWindowWidth: window.innerWidth,
        ...params
      }
    });

    if (response && response['ResponseCode'] === SUCCESS_API_CODE) {
      const {RedirectUrl} = response['Response'];
      if (RedirectUrl) {
        window.location.href = RedirectUrl;
      } else {
        window.location.hash = `/checkout/status/${params.Urlid}`;
      }
    } else {
      notify(response['ResponseText']);
    }
    setIsLoading(false);
  };

  const [cardNumber, cardNumberInput] = useCardInput({
    type: 'text',
    id: 'cardNumber',
    name: 'cardNumber',
    placeholder: '',
    autocomplete: 'cc-number',
  });

  const [cardHolder, cardHolderInput] = useInput({
    type: 'text',
    id: 'cardHolder',
    name: 'cardHolder',
    placeholder: '',
    autocomplete: 'cc-name',
  });

  const [cvv, cvvInput] = useCvvInput({
    type: 'text',
    id: 'cvv',
    name: 'cvv',
    placeholder: '',
  });

  const [isValidCvv, setIsValidCvv] = useState(true);
  const [isValidCardholder, setIsValidCardholder] = useState(true);

  const checkCvv = () => {
    return cvv.length === 3 && /^\d+$/.test(cvv);
  };

  const checkCardholder = () => {
    return /^[a-zA-Z\s]+$/.test(cardHolder);
  };

  useEffect(() => {
    if (cvv) {
      setIsValidCvv(checkCvv())
    }
  }, [cvv]);

  useEffect(() => {
    if (cardHolder) {
      setIsValidCardholder(checkCardholder())
    }
  }, [cardHolder]);

  const [policy, setPolicy] = useState(false);

  const [month, monthSelect] = useSelect({
    type: 'month',
    placeholder: '',
    autocomplete: 'cc-exp-month',
  });

  const [year, yearSelect] = useSelect({
    type: 'year',
    placeholder: '',
    autocomplete: 'cc-exp-year',
  });

  const policyClass = {
    'card-checkbox-icon': true,
    'card-checkbox-checked': policy,
  };

  const isEnabledNext = cardNumber && cardHolder && month && year && cvv && policy && checkCvv() && checkCardholder();

  useEffect(() => {
    // Check if the page is being reloaded or refreshed
    let isReload = false;

    if (performance.getEntriesByType('navigation')[0]) {
      const navigationType = performance.getEntriesByType('navigation')[0].type;

      isReload = navigationType === 'reload';

    } else {
      console.log('Navigation Timing API is not supported.');
      isReload = window.performance.navigation.type === 1;
    }

    if (isReload) {
      history.replace(`/checkout/list/${urlid}`);
    }
  }, [history]);

  useEffect(() => {
    return () => {
      dispatch(setInstance(null));
    }
  }, []);

  return isLoading ? (
    <LoadPanel/>
  ) : (
    <form className={'card-wrapper'}>
      <div className={'card-form'}>
        <div className={'card-title'}>
          {`${t('card_data')}:`}
          {orderAmount && orderAmount.isDifferentCurrency ? (
            <span
              className={'sub-card-title'}
            >
              {`${t('to_be_paid')}: ${orderAmount.paymentOrderAmount.Amount} ${orderAmount.paymentOrderAmount.CurrencyCode}`}
            </span>
          ) : null}
        </div>
        <div className={'card-row'}>
          <label className={'card-label'} htmlFor="cardNumber">
            {`${t('card_number')}*`}
          </label>
          <div className={'card-input'}>
            {cardNumberInput}
          </div>
        </div>
        <div className={'card-row'}>
          <label className={'card-label'} htmlFor="cardHolder">
            {`${t('cardholder_name')}*`}
          </label>
          <div className={Classnames('card-input', {'input-validation-error': !isValidCardholder})}>
            {cardHolderInput}
            {!isValidCardholder && (
              <div className={'input-validation-error-text'}>
                {t('latin_allowed')}
              </div>
            )}
          </div>
        </div>
        <div className={'card-row card-select-wrap'}>
					<span className={'card-label'}>
						{t('expires')}
					</span>
          <div className={'card-select extra-space'}>
            {monthSelect}
          </div>
          <div className={'card-select'}>
            {yearSelect}
          </div>
        </div>
        <div className={'card-row card-select-wrap'}>
					<span className={'card-label'}>
						CVV2/CVC2/CAV2*
					</span>
          <div className={Classnames('card-input extra-space', {'input-validation-error': !isValidCvv})}>
            {cvvInput}
            {!isValidCvv && (
              <div className={'input-validation-error-text'}>
                {t('must_contain_three_digit')}
              </div>
            )}
          </div>
          <div className={'card-select'}>
            <div
              // tabIndex={0}
              onClick={() => {
                setPolicy(!policy);
              }}
              onKeyDown={({charCode}) => {
                if (charCode === 13 || charCode === 32) {
                  setPolicy(!policy);
                }
              }}
              className={'card-checkbox'}
            >
              <i className={Classnames(policyClass)}/>
              <div
                className={'card-checkbox-text'}
              >
                <span>{t('i_agree_with')}</span>
                <a
                  target={'_blank'}
                  href={'https://sharpay.net/docs/Terms_and_Conditions_eng.pdf'}
                >
                  {t('the_conditions')}
                </a>
              </div>
            </div>
          </div>
        </div>
        {orderAmount && orderAmount.isDifferentCurrency && (
          <div className={'important-message'}>
            {t('message', {
              Amount: orderAmount.paymentOrderAmount.Amount,
              CurrencyCode: orderAmount.paymentOrderAmount.CurrencyCode,
            })}
          </div>
        )}
      </div>
      <div className={Classnames('card-buttons', {
        'mobile-buttons': false
      })}>
        <div className={'logo-block'}>
          <img className={'pp-logo'} src="/img/pp-icons/verified_by_visa_logo.png" alt="visa"/>
          <img className={'pp-logo'} src="/img/pp-icons/mastercard-secure-code.png" alt="master card"/>
          <img className={'pp-logo'} src="/img/pp-icons/pcidss_logo.png" alt="PCI DSS"/>
          {parseInt(paymentSystemKindID, 10) === 22 ? (
            <img className={'pp-logo'} src="/img/pp-icons/logo-gor-black.png" alt="Platon"/>
          ) : null}
        </div>
        <div className={'buttons-block'}>
          <button
            onClick={goBack}
          >
            {t('cancel')}
          </button>
          <button
            disabled={!isEnabledNext}
            className={!isEnabledNext ? 'button-disabled' : 'button-enabled'}
            onClick={(e) => {
              e.preventDefault();

              createCardPayment({
                Urlid: urlid,
                PaymentSystemTypeID: parseInt(paymentSystemKindID, 10),
                CardNumber: cardNumber.replace(/\s/g, ''),
                CardHolderName: cardHolder,
                CardExpireDate: `${month}${year}`,
                CardCode: cvv,
                PaymentCurrencyID: (orderAmount && orderAmount.paymentOrderAmount.CurrencyID) || null,
              }).catch((e) => {
                console.error(e);
              });
            }}
          >
            {t('next')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Card;
