import React, {useEffect, useState} from 'react';
import Classnames from 'classnames';

const createMonthType = () => {
	let monthList = [];

	for (let i = 1; i <= 12; i++) {
		let title = String(i);
		monthList.push({
			id: i,
			title: title.length === 1 ? `0${title}` : title,
		});
	}

	return monthList;
};

const createYearType = () => {
	let yearList = [];
	const date = new Date();
	const startYear = date.getFullYear();
	const stopYear = startYear + 15;

	for (let i = startYear; i <= stopYear; i++) {
		let title = String(i);
		yearList.push({
			id: i,
			title: title,
		});
	}

	return yearList;
};

const optionsList = [
	{
		id: 0,
		title: 'New York',
		selected: false,
		key: 'location'
	},
	{
		id: 1,
		title: 'Dublin',
		selected: false,
		key: 'location'
	},
	{
		id: 2,
		title: 'California',
		selected: false,
		key: 'location'
	},
	{
		id: 3,
		title: 'Istanbul',
		selected: false,
		key: 'location'
	},
	{
		id: 4,
		title: 'Izmir',
		selected: false,
		key: 'location'
	},
	{
		id: 5,
		title: 'Oslo',
		selected: false,
		key: 'location'
	}
];

const getOptions = (type) => {
	switch (type) {
		case 'month':
			return createMonthType();
		case 'year':
			return createYearType();
		default:
			return optionsList;
	}
};

export default function useSelect({type, placeholder = '', autocomplete = 'off'}) {
	const options = getOptions(type);
	const [isListOpen, setIsListOpen] = useState(false);
	const [currentItem, setCurrentItem] = useState(null);
	const headerTitle = currentItem ? currentItem.title : placeholder;
	const arrowClass = {
		'dd-arrow': true,
		'dd-arrow-up': isListOpen,
	};

	const titleClass = {
		'dd-title': true,
		'dd-placeholder': !currentItem,
	};

	const closeOutside = () => {
		setIsListOpen(false);
	};

	useEffect(() => {
		if (isListOpen) {
			window.addEventListener('click', closeOutside);
		} else {
			window.removeEventListener('click', closeOutside);
		}
	}, [isListOpen]);

	const [selectInputValue, setSelectInputValue] = useState('');

	const selectInput = <div className="dd-wrapper">
		<div className={'dd-inner'}>
			<input
				tabIndex={-1}
				autoComplete={autocomplete}
				className={Classnames(titleClass, 'select-input')}
				value={selectInputValue}
				onChange={(event) => {
					setSelectInputValue(event.target.value);
				}}
			/>
			<button
				tabIndex={0}
				type="button"
				className="dd-header"
				onClick={(e) => {
					e.stopPropagation();
					setIsListOpen(!isListOpen);
				}}
			>
				<i className="dd-arrow"/>
			</button>
		</div>
		{isListOpen && (
			<div className="dd-list-outer">
				<div
					className={'dd-list-header'}
				>
					<input tabIndex={-1} className={Classnames(titleClass, 'select-input')} value={selectInputValue}/>
					<i className={Classnames(arrowClass)}/>
				</div>
				<div
					role="list"
					className="dd-list"
				>
					{options.map((item) => (
						<button
							tabIndex={0}
							type="button"
							className={Classnames('dd-list-item', {
								'dd-list-item-active': headerTitle === item.title
							})}
							key={item.id}
							onClick={(e) => {
								e.stopPropagation();
								setCurrentItem(item);
								setSelectInputValue(item.title);
								setIsListOpen(false);
							}}
						>
							{item.title}
						</button>
					))}
				</div>
			</div>
		)}
	</div>;

	return [selectInputValue, selectInput];
}
